import React from 'react'
import { FaSmile } from 'react-icons/fa';
import { FaFrown } from 'react-icons/fa';

type QuestionAttemptResponse =
{
    message: string,
    question_attempt_results: {
        answer: string,
        score: number,
        error_flag: boolean,
        completed: boolean,
        correct_answer: string
    },
    question_id: number,
    question_format: number,
    words_scramble_direction: string,
    question_number: number,
    accumulated_score: number,
    questions_exhausted: boolean
}

export function QuestionAttemptResults(props:{response: QuestionAttemptResponse}) {
    //console.log("XXXXQQQQ", props.response.question_attempt_results.answer)
    const displayFormattedAnswer = (ans_str: string) => {
        if (props.response.question_format === 6) {
            //const correct_answer_str = props.response.question_attempt_results.correct_answer.replaceAll('/',' ')
            const formatted_answer_str =ans_str.replaceAll('/',' ')
            return (
                <div className='mx-3'>{formatted_answer_str}</div>
            )
        }
        else {
            return (
                <div>{ans_str}</div>
            )
        }
    }

    return (
        <>
            {props.response.question_attempt_results.error_flag &&
            <div className='flex flex-row gap-1'>
            <div className='text-blue-600 text-xl m-2'>
                <FaFrown />
            </div>
            <div>
                <div className='m-1 mx-2 text-red-600 text-lg'>Sorry!</div>
            </div>
            </div>
            }

            <div className='m-2'>Your answer is: {displayFormattedAnswer(props.response.question_attempt_results.answer)}</div>
            <div>{props.response.question_attempt_results.error_flag ?
                <div className='m-2'>The correct answer is:
                    {displayFormattedAnswer(props.response.question_attempt_results.correct_answer)}
                </div>
                :
                <div className='text-lg text-orange-600 mx-2'> 
                <FaSmile />
              </div>
            }
            </div>
            <div className='m-2'>Your score: {props.response.accumulated_score}</div>
        </>
    )
}
