
import { Link, useParams } from 'react-router-dom';
import { useAxiosFetch } from '../components/services/useAxiosFetch';
import { useAppSelector } from '../redux/store';
import { GrInProgress } from "react-icons/gr";
import { MouseEventHandler, useContext, useState } from 'react';
import SocketContext from '../components/context/Socket/Context';

type SubCategory = {
    categoryId: number,
    id: number,
    name: string
    sub_category_number: number
    level: string
    units:
    {
        id: number
        name: string
        unit_number: number
        level: string
        content: string
        subCategoryId: string
        quizzes: {
            id: number
            name: string
            quiz_number: number
        }[]
    }[]
}

export function SubCategoryPageTeacher(props:any) {
   
    const params = useParams<{ sub_categoryId: string }>();
    const { data: sub_category, loading: sub_loading, error: sub_error } = useAxiosFetch<SubCategory>({ url: `/sub_categories/${params.sub_categoryId}`, method: 'get' });
    const user = useAppSelector(state => state.user.value)
   
    const live_quiz_id = useAppSelector(state => state.live_quiz_id.value)
   
    const [startingQuestion, setStartingQuestion] = useState<string>('1')
    const [targetStudent, setTargetStudent] = useState<string>('everybody')

    const {socket, uid, users} = useContext(SocketContext).SocketState;

    const send_enable_live_quiz: MouseEventHandler<HTMLButtonElement> = (event) => {
        const el = event.target as HTMLButtonElement
        //console.log(el)
        //console.log("XXX id=", el.id)
        //const arg = {live_quiz_path: `/categories/${sub_category?.categoryId}/sub_categories/${sub_category?.name}/quizzes/live/${el.id}/${startingQuestion}`, target_student: targetStudent}
        const arg = {quiz_id: `${el.id}`, question_number: `${startingQuestion}`, target_student: targetStudent}
        console.log("SENNNN arg=", arg)
        socket?.emit('enable_live_quiz', arg)
    }

    if (live_quiz_id) {
        return (
            <>
                <div className='flex flex-row justify-center m-2 text-lg'>{sub_category?.name} </div>
                <div className='grid grid-cols-12'>
                    <div className='col-span-9 grid grid-rows bg-blue-100 rounded-lg'>
                        {sub_category?.units.map(unit => (
                            <div key={unit.id}>
                                <div className='m-2 text-md'>{unit.name}</div>
                                <div className='flex flex-row m-2 bg-blue-200 gap-2 p-1 rounded-lg'>{unit.quizzes.map(quiz =>
                                    <div className='flex flex-row' key={quiz.id}>
                                        {quiz.id.toString() === live_quiz_id &&
                                            <div className='flex flex-row gap-2'>
                                                <button className='bg-green-300 rounded-md hover:bg-green-500' id={quiz.id.toString()} onClick={send_enable_live_quiz}>Send Live Question</button>
                                                <span className='p-2 text-sm'>{quiz.id}</span>
                                               
                                                <span className='text-sm text-amber-900 mx-1'>Quiz {quiz.quiz_number}: </span>
                                                <Link className='underline text-sm' to={`/categories/${sub_category.categoryId}/quizzes/live/${quiz.id}/${startingQuestion}`}>{quiz.name}
                                                </Link>
                                                <span className='mx-3 text-red-600'><GrInProgress /></span>
                                                <span>
                                                        <span>Starting question:</span>
                                                        <span className='mx-1 '><input className='bg-amber-300 px-2 text-sm rounded-md w-4/12' type="text" value={startingQuestion}
                                                            onChange={e => setStartingQuestion(e.target.value)}
                                                        /></span>
                                                           <span className='mx-1 '>Target student:<input className='bg-amber-300 px-2 text-sm rounded-md w-4/12' type="text" value={targetStudent}
                                                            onChange={e => setTargetStudent(e.target.value)}
                                                        /></span>
                                                </span>
                                            </div>
                                        }
                                    </div>
                                )}</div>
                            </div>
                        ))}

                    </div>
                    <div className='col-span-3'>


                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            <div className='flex flex-row justify-center m-1 text-lg'>{sub_category?.name} </div>
            <div className='grid grid-cols-10'>
                <div className='col-span-3 grid grid-rows bg-gray-100 rounded-lg'>
                    {sub_category?.units.map(unit => (
                        <div key={unit.id}>
                        <div className='m-2 text-md'>{unit.name}</div>
                        <div className='flex flex-col m-2 bg-gray-200 gap-1 rounded-lg p-2'>{unit.quizzes.map(quiz =>
                             <div key={quiz.id}>
                                    <span className='p-2 text-sm'>{quiz.id}</span>
                            
                                <span className='text-sm text-red-800'>Quiz {quiz.quiz_number}: </span>
                                <Link className='underline text-sm' to={`/categories/${sub_category.categoryId}/sub_categories/${sub_category.name}/quizzes/${quiz.id}`}>{quiz.name}</Link>
                           </div>
                        )}</div>
                        </div>
                    ))}
                 
                </div>
                <div className='col-span-7'>
               
                  
                </div>
            </div>
        </>
    )
}