import React, { useState, useEffect, useContext, MouseEventHandler, useCallback, useRef } from 'react';
import { Card } from './Card';
//import clsx from 'clsx';//

import { getAGame } from './services/list';
//import TtSpeechProvider from './context/AzureTtsContext';
//import {UserContext} from '../context/UserContext'

import { useParams } from 'react-router-dom';
import Counter from './Counter';
import { CounterRef } from '../components/Counter';

export interface CardProps {
  id: number;
  text: string;
  matched: boolean;
  flipped: boolean;
  match_index: number;
  bgcolor: string;
  handle_choice?: (card: CardProps) => void;
}

const MemoryGame: React.FC = () => {
  const [cards, setCards] = useState<CardProps[]>([]);
  const [name, setName] = useState<string>('')
  const [choiceOne, setChoiceOne] = useState<CardProps>()
  const [choiceTwo, setChoiceTwo] = useState<CardProps>()
  const [numMatches, setNumMatches] = useState(0)
  const [gameover, setGameOver] = useState(false)
  const [gameStarted, setGameStarted] = useState(false)


  const counterRef = useRef<CounterRef>(null)
  //purple, cyan, slate, yellow, lime, emerald, teal, sky, indigo, violet, fuchsia, rose

  const [randomColor, setRandomColor] = useState<string>('')
  //const params = useParam<{ quizId: string, sub_category_name:string} 
  const params = useParams<{ game_id: string, backcolor: string }>();

  const handleChoice = (card: CardProps) => {
    if (choiceOne === undefined) {
      //console.log("choice ONe is undefined. Set card to choice ONE")
      setChoiceOne(card)
    }
    else {
      //console.log("choice ONe is NON NULL. Set card to choice TWO")
      setChoiceTwo(card)
    }
  };
  /*
  const getRandomString1 = useCallback(() => {
    const randomIndex = Math.floor(Math.random() * strings.length);
    return strings[randomIndex];
  }, [strings]);
*/
  
  
  useEffect(() => {
    getAGame(params.game_id)
      .then((data: any) => {
        //console.log("XXXXXYYYYYY", data)
        setName(data.name)
        let myArray1 = data.base.split('/').map((str: string, index: number) => {
          return (
            { text: str.trim(), matched: false, flipped: false, match_index: index }
          )
        });
        //console.log("myArray1 = ", myArray1)
        // setCardGroup1(myArray1)
        let myArray2 = data.target.split('/').map((str: string, index: number) => {
          return (
            { text: str.trim(), matched: false, flipped: false, match_index: index }
          )
        });
        const shuffledCards = [...myArray1, ...myArray2].sort(() => Math.random() - 0.5).map((card, index) => ({
          id: index,
          text: card.text,
          flipped: false,
          matched: false,
          bgcolor: 'bg-green-200',
          match_index: card.match_index,
          //handleChoice: void, //note: handleChoice is made optional in CardProps so it's not required in here
        }));
        // console.log("BBBBB", shuffledCards)
        setCards(shuffledCards);
        setGameStarted(true)
      })
  }, [params.game_id])

  useEffect(() => {
    if (numMatches === 1) {
        setGameOver(true)
        counterRef.current?.stopCount()
    }
},[numMatches])

useEffect(() => {
  if (gameStarted) {
      console.log("gameStarted. begin start Count")
      counterRef.current?.startCount()
  }
},[gameStarted])

  useEffect(() => {
    //console.log("in useEffect...choice1", choiceOne)
    //console.log("in useEffect...choice2", choiceTwo)
    if (choiceOne && choiceTwo) {
      //console.log(" choice1 and choice2")
      if (choiceOne.match_index === choiceTwo.match_index) {
        //console.log(" choice1 === choice2")
        setCards(prevState => {
          return prevState.map(card => {
            if (card.match_index === choiceOne.match_index) {
              //console.log("matched.... match index", card.match_index)
              return { ...card, matched: true }
            }
            else {
              return card
            }
          }
          );
        })
        setNumMatches(prevNumMatches => prevNumMatches + 1)
        resetTurn()
      }
      else {
        //resetTurn()
        setTimeout(() => resetTurn(), 700)
      }
    }

  }, [choiceOne, choiceTwo])

  const resetTurn = () => {
    setChoiceOne(undefined)
    setChoiceTwo(undefined)
  }

  return (
    <>
    <div>game started: {gameStarted.toString()}</div>
    <div className='flex flex-row justify-center mx-10 mt-3 text-xl font-bold text-green-700'>{name}</div>
    <div className='ml-14'><Counter initialSeconds={0} ref={counterRef} /></div>
      <div className='flex justify-center mt-8'>
        <div className='grid grid-cols-4 gap-1'>
          {cards.map((card: CardProps, index) => (
            
             <Card key={index} 
              id={card.id} text={card.text} 
              matched={card.matched}
              bgcolor={params.backcolor!}
              flipped={card.id === choiceOne?.id || card.id === choiceTwo?.id || card.matched}
              match_index={card.match_index} 
              handle_choice={handleChoice} 
              />
          )
          )}
        </div>
      </div>
    </>
  );
};

export default MemoryGame;