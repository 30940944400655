import {useContext, useEffect, useState} from 'react'

import { useAppSelector , useAppDispatch} from '../redux/store'
import { Link, NavLink, Outlet, useNavigate } from 'react-router-dom'
import { useAxiosFetch } from './services/useAxiosFetch'
//import { useSocket} from '../components/context/socketContext'
import { setLiveQuizId } from '../redux/live_quiz_id'
import SocketContext from './context/Socket/Context'
import ReactTextareaAutosize from 'react-textarea-autosize'
import PeerComponentStudent from './PeerComponentStudent'
import PeerComponentTeacher from './PeerComponentTeacher'
import ReactPlayer from 'react-player'
import { Dictaphone } from './Dictaphone'

  type Category = {
    id: number;
    name: string;
    sub_categories: SubCategory[]
}

interface SubCategory {
  id: number,
  name: string
  sub_category_number: number
  level: string
}

export function Home() {
    const user = useAppSelector(state => state.user.value)
    const { data: categories, loading, error } = useAxiosFetch<Category[]>({ url: '/categories', method: 'get' });
    
    const strings: string[] = ["bg-red-200", "bg-blue-200", "bg-purple-200", "bg-cyan-200", "bg-slate-200",
  "bg-lime-200", "bg-green-200", "bg-amber-100", "bg-orange-100",  "bg-emerald-200", "bg-sky-200", "bg-indigo-100", "bg-rose-100",
  "bg-yellow-200", "bg-fuchsia-200", "bg-teal-100",
  ];

    //const [localLiveQuizId, setLocalLiveQuizId] = useState<string>('')
    // this is not needed but keep it for Typescript learning
      /*  Initialize localLiveQuizId with an empty string to avoid this error:
        A component is changing an uncontrolled input to be controlled. This is likely caused...

         <select className='cloze_answer'>
          { (classIds as string[]).map( (classId, index) => {
               return <option key={index} id={index.toString()} >{classID} </option>
          })
          }
        </select>
    */

    const live_quiz_id = useAppSelector(state => state.live_quiz_id.value)
    const [gameId, setGameId] = useState<string>()
    
    const dispatch = useAppDispatch()

    const {socket, uid, users} = useContext(SocketContext).SocketState;
    const navigate = useNavigate();
  
    useEffect(() => {
        if (user.role === 'teacher') 
            return

      if (socket) {
        socket.on('enable_live_quiz', (arg: { quiz_id: string, question_number: string, target_student: string }) => {
          if (arg.target_student.trim() === 'everybody') {
            navigate("/live_quiz", { state: arg })
          }
          else if (arg.target_student.trim() === user.user_name?.trim()) {
            navigate("/live_quiz", { state: arg })
          }
          else {
            console.log(" invalid student target")
          }
        })
        return () => {
          socket?.off("enable_live_quiz")
        }
      }
    },[socket, navigate, user.user_name, user.role])

  useEffect(() => {
    if (user.role === 'teacher')
      return

    if (socket) {
      socket.on('enable_game', (arg: { game_id: string, backcolor: string }) => {
        //console.log(" game....", arg)
        navigate(`/live_game/${arg.game_id}/${arg.backcolor}`)
        /*
        if (arg.target_student.trim() === 'everybody') {
          navigate("/live_game", { state: arg })
        }
        else if (arg.target_student.trim() === user.user_name?.trim()) {
          navigate("/live_game", { state: arg })
        }
        else {
          console.log(" invalid student target")
        }
        */
      })
      return () => {
        socket?.off("enable_game")
      }
    }
  }, [socket, navigate, user.user_name, user.role])

  const enableGame = () => {
   
      function getRandomString() {
        const randomIndex = Math.floor(Math.random() * strings.length);
        return strings[randomIndex];
      }
      
      //const randomString = getRandomString();
      const randombackground = getRandomString()
      //console.log("xxxyyyyxxxx", randomString); 
      //eslint-disable-next-line 
   
    socket?.emit("enable_game", {game_id: gameId, backcolor: randombackground})
  }


/*
        {user.role === 'teacher' ?
        <PeerComponentTeacher items={['aaaaa', 'bbbbb']} />
        :
        <PeerComponentStudent />
        }
//
*/
    return (
        <>
        
        <div className='m-14'>
          <div className='flex flex-row gap-2 bg-green-50'>
            <div>Welcome: {user.user_name}</div>
            <div className='text-red-600 text-md'>
              <Link to="/logout">Log out</Link>
            </div>
            <div className='bg-amber-600 p-1 rounded-md text-white mr-1'><Link to="/">Home</Link></div>
            {user.role === "teacher" &&
              <div className='text-md flex flex-row'>
                <span className='mx-1'>Live quiz id:</span>
                <span className='mx-1 '><input className='bg-amber-400 px-2 text-sm rounded-md w-4/12' type="text" value={live_quiz_id}
                  onChange={e => dispatch(setLiveQuizId({ value: e.target.value }))}
                /></span>
                {live_quiz_id && <><span>Live Quiz</span><span className='text-red-500 mx-1'>ON</span>
                </>}
                <button className='bg-yellow-400 p-1 rounded-md mx-2' onClick={enableGame}>Enable Game</button>
                <span className='mx-1 '><input className='bg-green-200 px-2 text-sm rounded-md w-4/12' type="text" value={gameId}
                  onChange={e => setGameId(e.target.value)}
                /></span>
              </div>
             
            }
          </div>
          <div className="flex flex-row gap-2 mt-0 justify-around">
            <div className="flex flex-row p-0 gap-1">
           
              {categories?.map((category) => (
                <div key={category.id} className='flex flex-row'>
                  <NavLink
                    to={`/categories/${category.id}`}
                    className={({ isActive }) => {
                      return isActive ? 'text-lg bg-cyan-300 p-2 rounded-t-md' : 'rounded-md text-lg bg-cyan-100 p-2 hover:bg-cyan-200';
                    }}
                  >
                    {category.name}
                  </NavLink>

                </div>
              ))}

            </div>

          </div>
          <Outlet />
        </div>
        
        { user.role === 'student' &&
          <div className='ml-14'><Dictaphone  /></div>
        }
        <ReactTextareaAutosize className='bg-cyan-100 w-auto m-14 px-3' id="prompt"  value={user.message} />
        <p className='mx-14 text-gray-400'>
          {socket?.id !== undefined ?  
              <div>Socket Id: {socket.id}</div>
              :
              <div className='text-lg text-red-600'>EMPTY socket id.</div>
          }
       </p>
        </>
    )
}
/*
  <div className='m-0 flex justify-center text-cyan-500'><VscTriangleDown /></div>
*/