import { PayloadAction, createSlice } from '@reduxjs/toolkit'

type LiveQuizIdState = { 
  value: string | undefined
};

const initialState:LiveQuizIdState = { value: ''}

export const LiveQuizIdSlice = createSlice({
  name: 'livequizid',
  initialState,
  reducers: {
    clearLiveQuizId: (state) => {
      state.value = ''
    },
    setLiveQuizId: (state, action: PayloadAction<LiveQuizIdState>) => {
      state.value = action.payload.value
    },
  },
});

// Action creators are generated for each case reducer function
export const { clearLiveQuizId, setLiveQuizId } = LiveQuizIdSlice.actions

export default LiveQuizIdSlice.reducer
/*

*/