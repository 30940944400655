
import SpeechRecognition, { useSpeechRecognition } from  'react-speech-recognition'
import classNames from 'classnames';
import { FaMicrophone, FaMicrophoneSlash } from 'react-icons/fa';


    interface Props {
        content: string | undefined;
      }
    
      export interface ChildRef {
        getAnswer: () => string | undefined;
      }
    
export const Dictaphone = (props: any) => {

    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition,
        isMicrophoneAvailable
    } = useSpeechRecognition();
    
    const handleClick = () => {
        if (!listening) {
            SpeechRecognition.startListening({ continuous: true })
        }
        else {
            SpeechRecognition.stopListening()
        }
       
    }

    const reset_transcript = () => {
        resetTranscript()
        
    }
 
    if (!browserSupportsSpeechRecognition) {
        // Render some fallback content
        return (
            <div>Trình duyệt này không thể nhận giọng nói.</div>
        )
      }
    
      if (!isMicrophoneAvailable) {
        return (
            <div>Không thể dùng Microphone.</div>
        )
      }

    return (
        <>
        <div>{props.content}</div>
        {listening ? <p>Listening ...</p> : <p>&nbsp;</p>}
            <div className='flex flex-row'>
                <button  className={classNames(
                    'text-white',
                    'p-1',
                    !listening && 'bg-green-700',
                    listening && 'bg-red-700',
                    'rounded-md'
                    )}
                    onClick={handleClick} 
                    
                >
                    <div>
                    <FaMicrophone /> 
                    </div>
                </button>
                <button className='bg-amber-500 rounded-md p-1 mx-2' onClick={reset_transcript}>
                    Reset
                </button>
            </div>
            <p>&nbsp;</p>
            <p>{transcript}</p>
        </>
    )
}

/*
 return (
        <>
        <div>{props.content}</div>
        {listening ? <p>Listening ...</p> : <p>&nbsp;</p>}
            <div className='flex flex-row'>
                <button  className={classNames(
                    'text-white',
                    'p-1',
                    !listening && 'bg-green-700',
                    listening && 'bg-red-700',
                    'rounded-md'
                    )}
                    onClick={() => SpeechRecognition.startListening({ continuous: true })} 
                    disabled={listening}
                >
                    <div>
                    <FaMicrophone /> 
                    </div>
                </button>
            </div>
            <p>&nbsp;</p>
            <p>{transcript}</p>
        </>
    )
*/
