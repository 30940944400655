import { MouseEventHandler, useEffect, useRef, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import { useAppSelector } from '../redux/store';
import { useAxiosFetch } from '../components/services/useAxiosFetch';
import { QuestionProps } from '../components/Question'
import { DynamicInputs, ChildRef } from '../components/question_attempts/DynamicInputs';
import { createQuestionAttempt, processQuestionAttempt } from '../components/services/list';
import { DropDowns } from '../components/question_attempts/DropDowns';
import { ButtonSelect } from '../components/question_attempts/ButtonSelect';
//import { ButtonSelectPolly } from '../components/question_attempts/ButtonSelectPolly';
import { WordScrambler } from '../components/question_attempts/WordScrambler';
import { QuestionAttemptResults} from '../components/question_attempts/QuestionAttemptResults';
//import { FaPlayCircle } from "react-icons/fa";
import { WordsSelect } from '../components/question_attempts/WordsSelect';
import { RadioQuestion } from '../components/question_attempts/RadioQuestion';
import { ButtonSelectCloze } from '../components/question_attempts/ButtonSelecCloze';
import { SRContinuous } from '../components/question_attempts/SRContinuous';
import { AzureAudioPlayer } from '../components/AzureAudioPlayer';
import ReactPlayer from 'react-player';
import Counter from '../components/Counter';
import { CounterRef } from '../components/Counter';

//import Box from 'styled-system'
//import Flex from 'styled-system'
//import Slider from 'styled-system'
import '../components/MyReactPlayer.css'



type QuizAttempt = {
    question: QuestionProps,
    quiz_attempt_id: number,
    question_attempt_id: number
}

type QuestionAttemptResponse =
    {
        message: string,
        question_attempt_results: {
            answer: string,
            score: number,
            error_flag: boolean,
            completed: boolean,
            correct_answer: string
        },
        question_id: number,
        question_format: number,
        words_scramble_direction: string,
        question_number: number,
        accumulated_score: number,
        questions_exhausted: boolean
    }
  
      interface PageParamsProps {
        page_num: number
        numQuestions: number;
        startTime?: number;
        endTime?: number;
      }

      interface VideoProps {
        video_url: string,
        video_pages: PageParamsProps[]
    }

export function QuizPageVideo(props: any) {
    const params = useParams<{ sub_category_name: string, quizId: string,  }>();
    const user = useAppSelector(state => state.user.value)
    const url = `/quiz_attempts/find_create_new/${params.quizId}/${user.id}`
    //const url = `/quiz_attempts/${params.quizId}/${user.id}`
    //quiz_id/:user_id",
    const { data: quiz_attempt, loading, error } =
        useAxiosFetch<QuizAttempt>({ url: url, method: 'get' })

    const videoParams:VideoProps = useLocation().state
   
    //console.log("YYYYYY", videoParams.video_url)
    //console.log("ZZZZZ", videoParams.video_pages)

    const [showSubmitButton, setShowSubmitButton] = useState<boolean>(false)

    //console.log("<<<<<<<<<<", location.state)
    /*
[
    {
        "index": "1"
        "numQuestions": 2,
        "startTime": 0,
        "endTime": 20
    },
    {
        "index": "2"
        "numQuestions": 1,
        "startTime": 20,
        "endTime": 30
    }
]
    */

    const [question, setQuestion] = useState<QuestionProps | undefined>()
    const [showNextButton, setShowNextButton] = useState(false)
    const [questionAttemptResponse, setQuestionAttemptResponse] = useState<QuestionAttemptResponse>()
    const [endOfQuiz, setEndOfQuiz] = useState(false)
    const childRef = useRef<ChildRef>(null);

    const [answer, setAnswer] = useState<string>()

    const [playing, setPlaying] = useState(true);
    
    //const location = useLocation()
   // const videoParams:VideoProps = location.state

    useEffect(() => {
        //console.log(quiz_attempt)
        setQuestion(quiz_attempt?.question)
        setShowSubmitButton(true)
    }, [quiz_attempt])
    //useEffect(() => {
        //console.log(locationState.video_pages)
      
    //}, [videoParams])

    const handlePlay = () => {
        console.log("in handle play")
        setPlaying(true);
    
        //if (playerRef.current && currentVideoPage?.startTime) {
          //  playerRef.current.seekTo(currentVideoPage.startTime);
       // }
    };

    const handleProgress = (state: any) => {
        //if (currentVideoPage?.endTime && state.playedSeconds >= currentVideoPage?.endTime) {
           // console.log(" in handleProgress HERE. Stop playing because playedSeconds > video page endtime")
          //setPlaying(false);
          //if (playerRef.current && currentVideoPage?.startTime) {
           // playerRef.current.seekTo(currentVideoPage.startTime );
          //}
       // }
      };
   
      const handlePlayPause = () => {
        setPlaying(!playing);
      };

      const get_next_question = () => {
        //kpham. typescript tips: non-null assertion
        //server will decide the next question to fetch. KP
        //console.log(" in Quiz Page get next question")
        createQuestionAttempt(quiz_attempt!.quiz_attempt_id)
            .then((response) => {
                if (response.end_of_quiz) {
                    setEndOfQuiz(true)
                }
                else {
                    //console.log("next question", response.question)
                    setQuestion(response.question)
                    setShowSubmitButton(true)
                    setShowNextButton(false)
                    setQuestionAttemptResponse(undefined)
                }
            })
            .catch(error => {
                console.log(error)
            });
    }

    const handleSubmit = () => {
        const my_answer = childRef.current?.getAnswer();
        if (my_answer) {
            setAnswer(my_answer)
            //console.log("in handleSubmit answer =", answer)
            // use the ! (exclamation mark) = non-null assertion operator to avoid warning undefined not assignable to string
            processQuestionAttempt(quiz_attempt?.quiz_attempt_id, my_answer!)
                .then((response) => {
                    //setQuestion(undefined)
                    //console.log("UUUUUUU", response)
                    setShowNextButton(true)
                    setShowSubmitButton(false)
                    setQuestion(undefined)
                    setQuestionAttemptResponse(response)
                })
                .catch(error => {
                    console.log(error)
                });
        }
        else {
            alert("Enter answer")
        }

    }

    if (endOfQuiz) {
        return (
            <div>END OF QUIZ.</div>
        )
    }

    return (
        <>
            { videoParams.video_url &&
            <div>
                <div className='w-3/4 h-full'>
                    <div className="player-wrapper">
                        <div className="player-overlay"></div>
                        <ReactPlayer
                            playing={playing}
                            url={videoParams.video_url}
                            onPlay={handlePlay}
                            onProgress={handleProgress}
                            controls={false}
                        />
                    </div>
                </div>

                <div className='flex flex-row justify-between'>

                    <button onClick={handlePlayPause}>
                        {playing ? 'Pause' : 'Play'}
                    </button>

                </div>
            </div>
            }
            <div className='flex flex-row justify-center text-lg m-1'>{params.sub_category_name}</div>
            <div className='flex flex-col mx-20 mt-4'>
            <div className='mx-10 my-6 flex flex-col'>
                <div className='bg-gray-100 p-2 rounded-xl'>
                    {question &&
                        <>
                            <div className='text-amber-800'>Question: {question.question_number}</div>
                           
                            <div dangerouslySetInnerHTML={{ __html: question.instruction }}></div>
                            <div className='m-2'>{question.prompt}</div>
                            <div>
                                {(question.audio_str && question.audio_str.trim().length > 0) &&
                                    <AzureAudioPlayer text={question.audio_str} />
                                }
                                {(question.audio_src && question.audio_src.trim().length > 0) &&
                                    <audio src={question.audio_src} controls />
                                }
                            
                            </div>
                      
                            <div className='mt-3'>
                            { question.format === 1 ? (
                                <DynamicInputs content={question.content} ref={childRef} />
                            ) : question.format === 2 ? (
                                <ButtonSelectCloze content={question.content} ref={childRef} />
                            ) : question.format === 3 ? (
                                <ButtonSelect content={question.content} ref={childRef} />
                            ) : question.format === 4 ? (
                                <RadioQuestion question={question} ref={childRef} />
                            ) : question.format === 6 ? (
                                <WordScrambler content={question.content} ref={childRef} />
                            ) : question.format === 7 ? (
                                <SRContinuous content={question.content} ref={childRef} />
                            ) : question.format === 8 ? (
                                <WordsSelect content={question.content} ref={childRef} />
                            ) : question.format === 10 ? (
                                <DropDowns content={question.content} ref={childRef} />
                            ) : (
                                <div>UNKNOWN question format</div>
                            )}
                            </div>
                        </>
                    }
                </div>
                <div className='bg-green-200'>
                    {questionAttemptResponse?
                        <>
                            <QuestionAttemptResults response={questionAttemptResponse}  />
                        </>
                        :
                        <div></div>
                    }
                </div>
            </div>
            <div className='mx-10'>
            {showNextButton ?
                <button className='bg-amber-300 mt-0 text-lg p-1 rounded-md' onClick={get_next_question}>Next</button>
                :
                ( showSubmitButton &&
                <button className='bg-green-300 mt-0 text-lg p-1 rounded-md' onClick={handleSubmit}>Submit</button>
                )
            }
            </div>
            
            </div>
        </>
    )
}

