import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';

interface TimerProps {
  initialSeconds: number;
}
/*

 
  export interface ChildRef {
    getAnswer: () => string | undefined;
  }


  interface OptionProps {
    options: { value: string; label: string }[];
  }
  

export const RadioQuestion = forwardRef<ChildRef, Props>((props, ref) => {
  //
*/

interface Props {
    //content: string | undefined;
    initialSeconds: number
  }

  export interface CounterRef {
    getCount: () => number | undefined;
    startCount: () => void
    stopCount: () => void
  }

export const Counter = forwardRef<CounterRef, Props>((props, ref) => {
  const [count, setCount] = useState(0);
  //const [seconds, setSeconds] = useState<number>(0);
  const [minutesElapsed, setMinutesElapsed] = useState<number>(0)
  const [secondsElapsed, setSecondsElapsed] = useState<number>()
  const [stop, setStop] = useState(false)
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setCount((prevCount) => prevCount + 1);
    }, 1000);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  },[]);

  const getMinutes = () => {
    let minutes = 0
    if (count) {
      console.log(" in getMinutess here", count)
      minutes = Math.floor(count / 60);
    }
    else
      return 0

      console.log(" in getMinutess return ", minutes)
    return minutes
  }

  const getSeconds = (minutes: number) => {
    let m_seconds = 0
    //console.log(" in getSeconds count", count)
    //console.log(" in getSeconds minutes", minutes)
   // if (count && minutes) {
      m_seconds = count - minutes * 60;
      //console.log(" in getSeconds herer m seconds", m_seconds)
   // }
    //console.log(" in getSeconds herer1 m seconds", m_seconds)
    return m_seconds
  }

  useImperativeHandle(ref, () => ({
    startCount() {
      console.log(" in startCount")
      setStop(false)
      setCount(0)
    },

    stopCount() {
      //setSeconds(undefined)
      clearInterval(intervalRef.current!);
      const minutes = getMinutes()
      setMinutesElapsed(minutes)
      //console.log("MMMMM minutes", minutes)
      const m_seconds = getSeconds(minutes)
      //console.log("m seconds", m_seconds)
      setSecondsElapsed(m_seconds)
      setStop(true)
      return
    },
    getCount() {
      return count;
    }
  }));

  return (
    <div>
    {!stop ?
    <div>{count}&nbsp;&nbsp;&nbsp;</div>
    :
    <div>
        {minutesElapsed > 0 && <span>{minutesElapsed} minutes. </span>}
        <span>{secondsElapsed} seconds.</span>
    </div>
    }
    </div>
  );
});

export default Counter;