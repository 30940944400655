import React, { useState, useEffect, forwardRef, useImperativeHandle} from 'react';


interface InputField {
  id: string;
  type: string;
  value: string;
}

interface Props {
    content: string | undefined;
  }

  export interface ChildRef {
    getAnswer: () => string | undefined;
  }

  export const DynamicInputs = forwardRef<ChildRef, Props>((props, ref) => {
 
  const [inputFields, setInputFields] = useState<InputField[] | undefined >([])
  const [maxLength, setMaxLength] = useState<number>()
  /*
  const [inputFields, setInputFields] = useState<InputField[]>([
    { id: '1', type: 'static_text', value: 'who' },
    { id: '2', type: 'static_text', value: 'are' },
  ]);
  */

  const handleInputChange = (id: string, value: string) => {
    setInputFields((prevFields) =>
      prevFields?.map((field) => (field.id === id ? { ...field, value } : field))
    );
  };

  
 //useEffect(() => {
    //  console.log("HERE")
 // },[])

  useEffect(() => {
    const regExp = /\[.*?\]/g
    const  matches = props.content?.match(regExp);

    let length_of_longest_word = 1;
    if (matches) {
      for (var i = 0; i < matches.length; i++) {
        if (matches[i].length > length_of_longest_word) {
          setMaxLength(matches[i].length + 1)
        }
      }
    }

    /*
    var regExp = /\[.*?\]/g
 var matches = question.content.match(regExp);
             //matches =  [ '[^am/is]', '[^was/were]' ]
             let length_of_longest_word = 1;
             //console.log("leng of longest word"+length_of_longest_word)

        for (var i = 0; i < matches.length; i++) {
            if (matches[i].length > length_of_longest_word) {
                    length_of_longest_word = matches[i].length
            }
            var str = matches[i];
            if (str.indexOf('/') >= 0 ) {
                isDropdown = true;
            }
        }
    */

    //matches = ["[sentence]","[square brackets]"]
    //remove the square brackets from matches
    const matches_no_brackets =  matches?.map((item) => {
        return item.replace('[', '').replace(']', '')
    })


    // Use a regular expression to split the sentence
    const array = props.content?.split(/\[|\]/);
    
    // Filter out empty strings that might result from consecutive brackets
    const filteredArray = array?.filter(item => item.trim() !== "");
  
    const cloze_content_array = filteredArray?.map((part, index) => {
      const found = matches_no_brackets?.find((match) => part === match);
      if (found)
        return { id: index.toString(),  type: 'input', value: "  ",}
      else
        return { id: index.toString(), type: 'static_text', value: part}
    })
    
    setInputFields(cloze_content_array)
    
  },[props.content])

  const getAnswer = () => {
    //console.log("here")
    var input_error = false
    var user_answer;
    
    var cloze_answers = document.getElementsByClassName("cloze_answer");
    //console.log("XXXXXXXXXXXX", cloze_answers)
    const marray = [];
    for (let i = 0; i < cloze_answers.length; i++) {
      var input_el = cloze_answers[i] as HTMLInputElement
      //console.log("ZZZZZZZZZZZZ"+input_el.value)
      
      if (input_el.value.length > 0 ) {
              marray.push(input_el.value);
      }
      else {
            input_error = true
      }
    }
    if (!input_error ) {
      if (marray.length > 1) {
        user_answer = marray.join('/')
      }
      else {
        user_answer = marray[0]
      }
      //return user_answer
      //props.setUserAnswer(user_answer)
    }
    //const butt = document.getElementById('submit')
    //butt?.setAttribute("disabled", "");
    //setButtonDisabled(true)
    return user_answer
  }

  /**
   * Expose the `test` function to the parent component.
   */
  useImperativeHandle(ref, () => ({
    getAnswer,
  }));

  return (
    <>
    
    <div className='flex flex-row flex-wrap gap-2'>
      {inputFields?.map((field) => (
        <div key={field.id}>
          { field.type === 'input' ?
          <input
          className='bg-red-200 rounded-md cloze_answer'
            type="text"
            value={field.value}
            size={maxLength}
            onChange={(e) => handleInputChange(field.id, e.target.value)}
          />
          :
          <span>{field.value}</span>
          }
        </div>
      ))}
     
    </div>
    
    </>
  );
});

/*
   className={classNames(
                    'text-white',
                    !buttonDisabled && 'bg-green-600',
                    buttonDisabled && 'bg-red-700',
                    'rounded-t-md'
                )}
*/
/*
<div 
     className={classNames(
      'text-white',
      !buttonDisabled && 'bg-green-600',
      buttonDisabled && 'bg-red-700',
      'rounded-t-md'
  )}
    >
      { buttonDisabled ?
      <button id='submit' disabled>Submit , disabled: {buttonDisabled.toString()}</button>
      :
      <button id='submit' onClick={getAnswer}>Submit, disabled: {buttonDisabled.toString()}</button>
      }
    </div>
*/