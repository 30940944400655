import './App.css';
import React, {useState} from 'react';
import { Home } from './components/Home';
import Login from './components/auth/Login';
import { Logout } from './components/auth/Logout';
import { useEffect } from 'react';
import { getIds } from './components/services/list';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
//import { SpeechConfig } from 'microsoft-cognitiveservices-speech-sdk';
//import UserProvider from './components/context/UserContext';
import TtSpeechProvider from './components/context/AzureTtsContext';
//npm import { ThemeContextProvider } from './components/context/ThemeContext';
//import { SocketProvider } from './components/context/socketContext';
import SocketContextComponent from './components/context/Socket/Component';
import CategoryPage from './pages/CategoryPage';
import { SubCategoryPageStudent } from './pages/SubCategoryStudent';
import { SubCategoryPageTeacher } from './pages/SubCategoryPageTeacher';

import { QuizPage } from './pages/QuizPage';
import { QuizPageLive } from './pages/QuizPageLive';
//import AWS from 'aws-sdk'
import { PollyProvider } from './components/context/PollyMultiple'
import MemoryGame from './components/MemoryGame';
import { QuizPageVideo } from './pages/QuizPageVideo';

interface AzureTtsConfigType {
    id: string;
    region: string;

  //setUser: (user: { name: string; email: string } | null) => void;
}

/*
//for Amazon Polly TTS
AWS.config.update ({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION
})

const polly = new AWS.Polly()

interface PollyContextType {
  synthesizeSpeech: (text: string, voiceId?: string) => Promise<void>;
  audioUrl: string | null;
}

export const PollyContext = React.createContext<PollyContextType | null>(null);
*/

function getAuthFromSessionStorage() {
  const tokenString = sessionStorage.getItem('auth');
  if (tokenString !== null)
    return JSON.parse(tokenString)
  else {
    return null
  }
}

export const AzureSpeechContext = React.createContext<AzureTtsConfigType | null>(null);

function App() {
  

  const [auth, setAuth] = useState(getAuthFromSessionStorage());
  const [categoryIds, setCategoryIds] = useState([])
  const [subCategoryIds, setSubCategoryIds] = useState([])
  const [unitIds, setUnitIds] = useState([])

  //const azureSpeechConfig = SpeechConfig.fromSubscription('0a1e83a35c7642c49b193de23611e07f', 'eastus');

  const onLogin = (userToken: string) => {
    setAuth(userToken)
    //also persits auth state in session Storage so that user is still logged after a page refresh
    sessionStorage.setItem('auth', JSON.stringify(userToken));
  }

  const onLogout = () => {
      sessionStorage.clear()
      setAuth(null)
  }

  useEffect(() => {
    if (!auth) {
      return
    }
    getIds() // fetch ALL game ids, sub_category ids, unit ids, quizzes id
      .then((response) => {
        //console.log("NNNNNNNN response.data", response.data)
        setCategoryIds(response.category_ids)
        setSubCategoryIds(response.sub_category_ids)
        setUnitIds(response.unit_ids)
      })
  }, [auth])

  if (!auth) {
    return (
    
    <Login onLoginSuccess={onLogin} />
  
    )
  }

  return (
    <>
   <SocketContextComponent>
      <TtSpeechProvider>
      <PollyProvider>
        <BrowserRouter>
          <Routes>
          <Route path="/logout" element={<Logout onLogout={onLogout} />} />
            <Route path="/" element={<Home />}>
                <Route path="/categories/:categoryId" element={<CategoryPage />}>
                  <Route path="sub_categories_student/:sub_categoryId" element={<SubCategoryPageStudent />} />
                  <Route path="sub_categories_teacher/:sub_categoryId" element={<SubCategoryPageTeacher />} />
                  <Route path="sub_categories/:sub_category_name/quizzes/:quizId" element={<QuizPageVideo />} />
                  
                </Route>
                <Route path="/live_quiz" element={<QuizPageLive />} />
                <Route path="/live_game/:game_id/:backcolor" element={<MemoryGame />} />
            </Route>
          </Routes>
        </BrowserRouter>
        </PollyProvider>
      </TtSpeechProvider>
      </SocketContextComponent>
    </>
  );
}

export default App;

//<Route path="sub_categories/:sub_category_name/quizzes/live/:quizId/:startingQuestionId" element={<QuizPageLive />} />