
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useAxiosFetch } from '../components/services/useAxiosFetch';
import { TakeQuizButton } from '../components/TakeQuizButton';

type SubCategory = {
    categoryId: number,
    id: number,
    name: string
    sub_category_number: number
    level: string
    units:
    {
        id: number
        name: string
        unit_number: number
        level: string
        content: string
        subCategoryId: string
        quizzes: {
            id: number
            name: string
            quiz_number: number
            disabled: boolean
            video_url: string
            unitId: number
        }[]
    }[]
}

export function SubCategoryPageStudent(props:any) {
   
    const params = useParams<{ sub_categoryId: string }>();
    const { data: sub_category, loading: sub_loading, error: sub_error } = useAxiosFetch<SubCategory>({ url: `/sub_categories/${params.sub_categoryId}`, method: 'get' });
    const navigate = useNavigate()

    const take_quiz = (quiz_id: number | undefined, url: string | undefined) => {
        if (sub_category) {
            const api_url = `/categories/${sub_category.categoryId}/sub_categories/${sub_category.name}/quizzes/${quiz_id}`
            navigate(api_url, {state: {video_url: url }})
        }
    }

    return (
        <>
            <div className='flex flex-row justify-center m-1 text-lg'>{sub_category?.name} </div>
            <div className='grid grid-cols-10'>
                <div className='col-span-3 grid grid-rows bg-gray-100 rounded-lg'>
                    {sub_category?.units.map(unit => (
                        <div key={unit.id}>
                        <div className='m-2 text-md'>{unit.name}</div>
                        <div className='flex flex-col m-2 bg-gray-200 gap-1 rounded-lg p-2'>{unit.quizzes.map(quiz =>
                             <div key={quiz.id}>
                                <span className='text-sm text-red-800'>Quiz {quiz.quiz_number}: </span>
                                <TakeQuizButton quiz_id={quiz.id} video_url={quiz.video_url} parentFunct={take_quiz} />
                          
                           </div>
                        )}</div>
                        </div>
                    ))}
                 
                </div>
                <div className='col-span-7'>
               
                  
                </div>
            </div>
        </>
    )
}

//  <Link className='underline text-sm' to={`/categories/${sub_category.categoryId}/sub_categories/${sub_category.name}/quizzes/${quiz.id}`}>{quiz.name}</Link>
